<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'messagesDataCards'"
          v-bind:theme="dataCards.total.theme"
          v-bind:name="dataCards.total.name"
          v-bind:value="dataCards.total.value | numFormat">
          <message-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'messagesDataCards'"
          v-bind:theme="dataCards.inbound.theme"
          v-bind:name="dataCards.inbound.name"
          v-bind:value="dataCards.inbound.value | numFormat">
          <message-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'messagesDataCards'"
          v-bind:theme="dataCards.outbound.theme"
          v-bind:name="dataCards.outbound.name"
          v-bind:value="dataCards.outbound.value | numFormat">
          <message-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'messagesDataCards'"
          v-bind:theme="dataCards.templateMsg.theme"
          v-bind:name="dataCards.templateMsg.name"
          v-bind:value="dataCards.templateMsg.value | numFormat"
          v-bind:show-download-button="true"
          v-on:download="downloadTemplatesFile">
          <message-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'messagesDataCards'"
          v-bind:theme="dataCards.templateMsgRead.theme"
          v-bind:name="dataCards.templateMsgRead.name"
          v-bind:value="dataCards.templateMsgRead.value | numFormat">
          <message-icon/>
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'messagesDataCards'"
          v-bind:theme="dataCards.templateRateMsg.theme"
          v-bind:name="dataCards.templateRateMsg.name"
          v-bind:prefix="'$'"
          v-bind:suffix="' USD'"
          v-bind:show-as-warning="templatesCostExceeded"
          v-bind:value="dataCards.templateRateMsg.value | numFormat('0.00')">
          <message-icon/>
        </data-card>
      </div>
    </div>

    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-12">
        <data-chart
          ref="messagesAgentVsClient"
          v-bind:translatable="true"
          v-bind:name="'chart.messages.agent-vs-client'"
          v-bind:chart-id="'messagesAgentVsClient'"
          v-bind:chart-data="dataCharts.messagesAgentVsClient"
          v-bind:type="'stackedColumn'"
          v-on:change="chartRangeChanged"/>
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="messagesPerHour"
          v-bind:null-to-zeroes="true"
          v-bind:name="'chart.messages.per-hour'"
          v-bind:chart-id="'messagesPerHour'"
          v-bind:chart-data="dataCharts.messagesPerHour"
          v-bind:type="'heatmap'"
          v-on:change="chartRangeChanged"/>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { executeQuery } from '@/utils/gql-api';
import MessagesDataCards from '@/graphql/queries/messages/dataCards.gql';
import MessagesAgentVsClient from '@/graphql/queries/messages/agentVsClient.gql';
import MessagesPerHour from '@/graphql/queries/messages/perHour.gql';

export default {
  components: {
    DataCard: () => import('@/components/DataCard.vue'),
    DataChart: () => import('@/components/DataChart.vue'),
  },
  props: {
    filters: {
      required: true,
    },
  },
  data() {
    return {
      dataCards: {
        total: {
          theme: 'teal',
          name: 'chart.card.msg-total',
          value: 0,
        },
        inbound: {
          theme: 'purple',
          name: 'chart.card.msg-inbound',
          value: 0,
        },
        outbound: {
          theme: 'violet',
          name: 'chart.card.msg-outbound',
          value: 0,
        },
        templateMsg: {
          theme: 'red',
          name: 'chart.card.msg-template',
          value: 0,
        },
        templateMsgRead: {
          theme: 'red',
          name: 'chart.card.read-msg-template',
          value: 0,
        },
        templateRateMsg: {
          theme: 'green',
          name: 'chart.card.msg-template-rate',
          value: 0,
        },
      },
      dataCharts: {
        messagesAgentVsClient: null,
        messagesPerHour: null,
      },
    };
  },
  computed: {
    templatesCostExceeded() {
      return this.dataCards.templateRateMsg.value >= 300;
    },
  },
  watch: {
    filters(newValue) {
      if (newValue !== null) {
        this.initAll();
      }
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.initAll();
    }
  },
  methods: {
    initAll() {
      this.loadCards();
      this.loadMessagesAgentVsClient();
      this.loadMessagesPerHour();
      this.resetFiltersFromCharts();
    },
    resetFiltersFromCharts() {
      if (this.$refs.messagesAgentVsClient) {
        this.$refs.messagesAgentVsClient.resetFilters();
      }

      if (this.$refs.messagesPerHour) {
        this.$refs.messagesPerHour.resetFilters();
      }
    },
    async loadCards() {
      const data = await this.fetchData('messagesDataCards', MessagesDataCards, this.filters);

      this.dataCards.total.value = data.total;
      this.dataCards.inbound.value = data.inbound;
      this.dataCards.outbound.value = data.outbound;
      this.dataCards.templateMsg.value = data.templateMsg;
      this.dataCards.templateMsgRead.value = data.templateMsgRead || '-';
      this.dataCards.templateRateMsg.value = this.calculateTemplatesRate(data.templateMsg);
    },
    async loadMessagesAgentVsClient(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.messagesAgentVsClient = await this.fetchData('messagesAgentVsClient', MessagesAgentVsClient, filters);
    },
    async loadMessagesPerHour(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.messagesPerHour = await this.fetchData('messagesPerHour', MessagesPerHour, filters);
    },
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');

        const filters = {
          startDate,
          endDate,
        };

        switch (chartId) {
          case 'messagesAgentVsClient':
            this.loadMessagesAgentVsClient(filters);
            break;
          case 'messagesPerHour':
            this.loadMessagesPerHour(filters);
            break;
          default:
            break;
        }
      }
    },
    calculateTemplatesRate(totalTemplates) {
      const rate = this.getCostRate(totalTemplates);
      return totalTemplates * rate;
    },
    getCostRate(total) {
      if (total <= 250000) {
        return 0.0266;
      }

      if (total <= 750000) {
        return 0.0262;
      }

      if (total <= 2000000) {
        return 0.0257;
      }

      if (total <= 3000000) {
        return 0.0243;
      }

      if (total <= 4000000) {
        return 0.0229;
      }

      return 0.0215;
    },
    async downloadTemplatesFile() {
      const response = await api.downloadReport('template', this.filters);
      const fileLink = document.createElement('a');

      fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
      fileLink.setAttribute('download', `Templates-${this.filters.startDate}-to-${this.filters.endDate}.xls`);
      fileLink.style.display = 'none';
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    },
  },
};
</script>
